<template>
    <div class="shop-manage">
        <div class="add-Goods"><el-button type="primary" plain @click="addBtn()">添加新商品</el-button></div>
        <el-table :data="goodsList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
            <el-table-column label="商品信息" min-width="400">
                <template slot-scope="scope">
                    <div class="" style="display: flex;margin: 10px 0;">
                        <span>商品编码：{{scope.row.goods_sku}}</span>
                        <span style="margin: 0 20px">所属类目：{{scope.row.name}}</span>
                        <span>商品货号：{{scope.row.goods_code}}</span>
                    </div>
                    <div class="goods-wrapper">
                        <div class="goods-cover">
                            <img :src="scope.row.goods_images" alt="">
                        </div>
                        <div class="text-overflow-2 goods-name" :title="scope.row.goods_title">{{scope.row.goods_title}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="stock" v-if="examModuleId===16" label="总库存" align="center"></el-table-column>
            <el-table-column prop="search_num" v-if="examModuleId===15" label="搜索人数" align="center"></el-table-column>
            <el-table-column prop="sale_price" v-if="examModuleId===16" label="销售价" align="center"></el-table-column>
            <el-table-column prop="click_rate" v-if="examModuleId===15" label="点击率" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.click_rate}}%</span>
                </template>
            </el-table-column>
            <el-table-column prop="conversion_rate" v-if="examModuleId===15" label="转化率" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.conversion_rate}}%</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="primary" v-if="examModuleId===15" :underline="false" @click="optimizeTitle(scope.row)">优化标题</el-link>
                    <el-link type="success" :underline="false" @click="editBtn(scope.row.id)">修改</el-link>
                    <el-link type="danger" :underline="false" @click="delBtn(scope.row)">删除</el-link>
                </template>
            </el-table-column>
            <template slot="empty">
                <div class="null-data">
                    <div style="display: flex;flex-direction: column;align-items: center;">
                        <img src="../../../../assets/images/student/goods-null-data.png" alt="">
                        <span>暂无商品</span>
                    </div>
                </div>
            </template>
        </el-table>
        <!-- S 优化标题弹窗-->
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" title="优化标题"
                   custom-class="green-dialog02" :visible.sync="optimizeDialogVisible" width="500px" @close="resetForm()">
            <el-form :model="optimizeForm" ref="optimizeForm" label-position="right" label-width="108px">
                <el-form-item label="优化标题"  prop="title">
                    <el-input v-model="optimizeForm.title"  autocomplete="off" style="width:340px"  maxlength="50"
                              show-word-limit
                              placeholder=""></el-input>
                </el-form-item>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="optimizeDialogVisible = false">取 消</el-button>
                <el-button style="margin-left: 30px" type="primary" @click="optimizeBtn()">确 定</el-button>
            </div>
        </el-dialog>
        <!-- E 优化标题弹窗-->
    </div>
</template>

<script>
    import {stuOpStuOpGoodsList,stuOpStuOptimizeTitle,stuOpStuOpGoodsDel} from '@/utils/apis'
    export default {
        name: "GoodsIndex" ,
        data(){
            return{
                examModuleId:null,
                goodsList: [],
                optimizeForm:{
                    id:'',
                    title:''
                },
                optimizeDialogVisible:false,
            }
        },
        mounted() {
            this.getStuOpStuOpGoodsList()
            if (this.$route.query.exam_module_id){
                this.examModuleId = Number(this.$route.query.exam_module_id)
            }

        },
        methods:{
            //添加按钮
            addBtn(){
                if (this.$route.query.id){
                    this.$router.push({
                        path: '/student/trainCenter/onlineShopManagement/studentGoodsCategory',
                        query:{
                            exam_module_id:this.$route.query.exam_module_id,
                            id:this.$route.query.id,
                            course_id:this.$route.query.course_id
                        }
                    })
                }else {
                    this.$router.push({
                        path: '/student/examing/onlineShopManagement/studentGoodsCategory',
                        query:{
                            exam_module_id:this.$route.query.exam_module_id
                        }
                    })
                }
            },
            // 商品列表
            getStuOpStuOpGoodsList(){
                let  param = {
                    type:'1'
                }
                if (this.$route.query.id) {
                    param.op_id = this.$route.query.id;
                }
                if (this.$route.query.course_id) {
                    param.course_id = this.$route.query.course_id;
                }
                stuOpStuOpGoodsList(param).then((res) => {
                    this.goodsList=res.data
                })
            },
            //在售商品修改按钮
            editBtn(id){
                if (this.$route.query.id) {
                    this.$router.push({
                        path: '/student/trainCenter/onlineShopManagement/studentGoodsCategory',
                        query: {
                            id: this.$route.query.id,
                            g_id: id,
                            exam_module_id:this.$route.query.exam_module_id,
                            course_id:this.$route.query.course_id,
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/examing/onlineShopManagement/studentGoodsCategory',
                        query: {
                            g_id: id,
                            exam_module_id:this.$route.query.exam_module_id
                        }
                    })
                }
            },
            //在售商品删除
            delBtn(row){
                this.$confirm(`是否删除该商品，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let params = {
                        id:row.id
                    }
                    stuOpStuOpGoodsDel(params).then((res) => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            duration:1000,
                            onClose: () => {
                                this.getStuOpStuOpGoodsList()
                            }
                        })
                    }).catch((err) => {
                        console.log('err', err)
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            //优化标题
            optimizeTitle(row){
                this.optimizeDialogVisible = true;
                this.optimizeForm.id = row.id;
                this.optimizeForm.title = row.goods_title;
            },
            //优化标题确定事件
            optimizeBtn(){
                this.optimizeDialogVisible = false;
                if (this.optimizeForm.title) {
                    let params = {
                        id:this.optimizeForm.id,
                        name:this.optimizeForm.title
                    }
                    if (this.$route.query.id) {
                        params.op_id = this.$route.query.id;
                    }
                    if (this.$route.query.course_id) {
                        params.course_id = this.$route.query.course_id;
                    }
                    stuOpStuOptimizeTitle(params).then((res) => {
                        this.$message.success(res.msg);
                        this.getStuOpStuOpGoodsList()
                    }).catch((err) => {
                        console.log(err);
                    })
                } else {
                    this.$message.warning('请填写标题！');
                }
            },
            //重置表单
            resetForm() {
                this.$refs.optimizeForm.resetFields();
                this.optimizeForm = this.$options.data().optimizeForm;
            },
        }
    }
</script>

<style scoped lang="scss">
    .shop-manage{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding:  0 20px;
        .add-Goods{
            margin-top: 20px;
        }
        .goods-wrapper {
            display: flex;
            align-items: center;
            .goods-cover {
                width: 80px;
                height: 80px;
                display: flex;
                background: #F6F6F6;
                img  {
                    max-width: 100%;
                    max-height: 100%;
                    margin: auto;
                }
            }
            .goods-name {
                width: 1%;
                flex: 1;
                margin-left: 10px;
                font-size: 16px;
            }
        }
        .dialog-footer{
            text-align: center;
            margin-top: 20px;
        }
    }

</style>